<template>
  <div class="shopMember">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/goods' }">商品管理</el-breadcrumb-item>
      <el-breadcrumb-item>商品列表</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="shop-screen">
      <el-form
        ref="form"
        :model="goodsForm"
        label-width="80px"
        size="mini"
        class="shopScreenForm"
      >
        <el-form-item label="门店名称">
          <el-input
            v-model="goodsForm.shopName"
            style="width: 260px"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            style="background: #cd0550; border: none"
            @click="search"
            >搜索</el-button
          >
          <el-button @click="emptySearch">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="data-table">
        <el-table
          :data="tableData"
          stripe
          style="width: 100%"
          type="index"
          :header-cell-style="{ background: '#CD0550', color: '#fff' }"
        >
          <el-table-column prop="id" label="ID"> </el-table-column>
          <el-table-column prop="shop.name" label="门店名称"> </el-table-column>
          <el-table-column prop="good_name" label="商品名称"> </el-table-column>
          <el-table-column prop="original_price" label="门市价格">
            <template slot-scope="scope">
                {{scope.row.original_price_range_start?scope.row.original_price_range_start+'-'+scope.row.original_price_range_end:scope.row.original_price}}
            </template>
          </el-table-column>
          <el-table-column prop="price" label="销售价格"></el-table-column>
          <el-table-column prop="platform_service_fee" label="平台服务费" v-if="is_supermarket!=1"> </el-table-column>
          <el-table-column prop="score" label="商品评分"></el-table-column>
          <el-table-column prop="status" label="状态">
            <template slot-scope="scope">
              {{scope.row.status==0?'下架':'上架'}}
            </template>
           </el-table-column>
          <el-table-column prop="is_edit" label="编辑商品">
            <template slot-scope="scope">
               {{scope.row.is_edit==0?'不可编辑':'可编辑'}}
            </template>
           </el-table-column>
            <el-table-column prop="" label="今日库存/剩余库存">
            <template slot-scope="scope">
              {{scope.row.today_num+'/'+scope.row.today_balance}}
            </template>
           </el-table-column>
          <el-table-column prop="good_image" label="图片" width="190px"> 
              <template slot-scope="scope">
                  <img :src="imgUrl+scope.row.good_image" width="170px" height="80px" alt="">
              </template>
          </el-table-column>
          <el-table-column prop="created_at" label="创建时间"> </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <div style="position: relative; display: inline">
                <i
                  style="color: #3ca6f0"
                  @click="operation(scope.row)"
                  class="iconfont icon-bianji"
                >
                </i>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="page-num">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-sizes="pageSizes"
            :page-size="pageSize"
            layout="prev,pager,next, total,sizes,jumper"
            :total="total"
            background
          >
          </el-pagination>
        </div>
      </div>
  </div>
</template>

<script>
import { imgUrl, httpUrl } from "@/utils/http.js"
import {getGoodsList_api} from "@/api/goods.js"
import local from "@/utils/local.js"
export default {
  data() {
    return {
      imgUrl:imgUrl,
      goodsForm: {
        shopName: "",
      },
      currentPage: 1,
      pageSizes: [10, 20,50],
      pageSize: 10,
      total: 0,
      tableData:[],
      active:1,
      is_supermarket:''
    }
  },
  methods: {
    //获取商品列表
    async getGoodsList(){
      let res=await getGoodsList_api({
        shop_name:this.goodsForm.shopName,
        page:this.currentPage,
        limit:this.pageSize
      })
      this.tableData=res.data.data.list
      this.total=res.data.data.total_count
    },
    //重置
    emptySearch() {
      this.goodsForm = {}
      this.currentPage=1
      this.getGoodsList()
    },
    //搜索
    search() {
      this.currentPage=1
      this.getGoodsList()
    },
    //改变条数
    handleSizeChange(e) {
      this.pageSize=e
      this.getGoodsList()
    },
     //切换页
    handleCurrentChange(e) {
      this.currentPage=e
      this.getGoodsList()
    },
    //去编辑
    operation(row) { 
      local.set("goodsInfo",JSON.stringify(row))
      this.$router.push('/goods/changeGoods')
    }
  },
  created(){
     this.goodsForm.shopName=local.get("shopName1")
    this.getGoodsList()
    this.is_supermarket=local.get("is_supermarket")
  },
  destroyed(){
    local.remove("shopName1")
  }
}
</script>

<style lang="less" scoped>
.shopMember {
  width: 100%;
  height: 100%;
  background: #eee;
  box-sizing: border-box;
  padding: 30px;
}
.shop-screen {
  background: #ffffff;
  border-radius: 5px;
  padding: 20px;
  box-sizing: border-box;
  margin-top: 20px;
}
.shopScreenForm {
  display: flex;
  font-size: 14px;
  font-weight: bold;
  color: #666666;
  margin-top: 20px;
}
/deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
  background: #cd0550 !important;
}
/deep/.el-pager li {
  border: 1px solid #bfbfbf;
  background: #fff !important;
  box-sizing: border-box !important;
}
/deep/.el-pagination.is-background .btn-next {
  border: 1px solid #bfbfbf;
  background: #fff !important;
}
/deep/.el-pagination.is-background .btn-prev {
  border: 1px solid #bfbfbf;
  background: #fff !important;
}
/deep/.el-table {
  overflow: visible;
}
/deep/td {
  text-align: center !important;
}
/deep/.cell {
  text-align: center !important;
  overflow: visible;
}
/deep/.el-table__body-wrapper {
  overflow: visible;
}
/deep/.is-active {
  color: #cd0550;
}
/deep/.el-tabs__active-bar {
  background: #cd0550;
}
.data-table {
  margin-top: 20px;
  background: #fff;
  border-radius: 5px;
  overflow: hidden;
}
.page-num {
  display: flex;
  justify-content: flex-end;
  padding: 30px;
}
.operation {
  cursor: pointer;
  position: absolute;
  top: 0px;
  left: -90px;
  z-index: 99;
  background: #fff;
  p {
    width: 85px;
    height: 40px;
    // background: rgba(246, 219, 229, 0.2);
    border-radius: 0px;
    text-align: center;
    line-height: 40px;
    border: 1px #eee solid;
    font-size: 14px;
    font-weight: bold;
    color: #999999;
  }
}
.active {
  background: #cd0550 !important;
  color: #fff !important;
}
</style>